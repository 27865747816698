import React, { useState, useEffect }  from "react";
import { Helmet } from "react-helmet";

import Spinner from "../components/Spinner";
import Header from "../components/Header";
import Master from "../layouts/master";

import { entriesService } from "../services/entries";

const Log = () => {
    const [entries, setEntries] = useState(null);

    async function getEntriesData() {
        const res = await entriesService.entries();
        setEntries(res.data);
    }

    useEffect(() => { getEntriesData() }, []);

    if(!entries) {
        return(
            <Master>
                <Helmet title="Kitter | Log" />
                <Header />
                <Spinner />
            </Master>
        );
    } else {
        return(
            <Master>
                <Helmet title="Kitter | Log" />
                <Header />
                <h1>Log</h1>
    
                <table>
                    <tr>
                        <th style={{width: '2.5em', minWidth: '2.5em'}}>Date-time</th>
                        <th style={{width: '2.5em', minWidth: '2.5em'}}>Guide</th>
                        <th style={{width: '20em', minWidth: '20em'}}>Kits</th>
                        <th style={{width: '1em', minWidth: '1em'}}>In/Out</th>
                        <th style={{minWidth: '15em', minWidth: '15em'}}>Comments</th>
                    </tr>
                    {
                        entries.map((entry) => {
                            return(
                                <tr>
                                    <td>{ new Date(entry.createdAt).toLocaleString().replace(/T/, " ").replace(/\..+/, "") }</td>
                                    <td>{ entry.Guide }</td>
                                    <td>{ entry.Kits.map((kit) => {return kit.Name}).join(", ") }</td>
                                    <td>{ entry.In ? "In" : "Out" }</td>
                                    <td>{ entry.Comments }</td>
                                </tr>
                            )
                        })
                    }
                </table>
            </Master>
        );
    }
};

export default Log;
