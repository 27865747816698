import axios from "axios";

async function entries() {
    const response = await axios.get(process.env.GATSBY_CMS_HOST + "/entries?_sort=createdAt:DESC")
    .then(response => {
        return response;
    }).catch(error => {
        return false;
    })

    return response;
}

async function create(data) {
    const response = await axios.post(process.env.GATSBY_CMS_HOST + `/entries`, data)
    .then(response => {
        return true;
    }).catch(error => {
        return false;
    })

    return response;
}

export const entriesService = {
    entries,
    create
};